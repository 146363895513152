import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import intl from 'react-intl-universal';
import Confirmation from '~components/confirmation/confirmation';
import Processing from '~components/processing/processing';
import { IBillingAddress, IPaymentMethod } from '~interfaces/cardInfo';
import { transform } from '~services/utils';
import { Api } from '../repositories/api';
import { Store } from '../store';

interface IOrderData {
  userInfo: IBillingAddress;
  creditCardInfo: IPaymentMethod;
}

interface Redirect {
  url: string;
  method: 'POST';
  data: {
    MD: string;
    PaReq: string;
    TermUrl: string;
  };
}

const REACT_APP_POLLING_RETRY_DELAY_MS = 1000;

/**
 * Processing status refers to the page's status, rather than individual
 * operations to get the page to a final stage. The process can have status:
 *  - PENDING; if processing is ongoing
 *  - SUCCEEDED; if processing is finished and was successful
 *  - FAILED; if processing is finished and was not successful
 */

enum ProcessingStatus {
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

interface ProcessingState {
  status: ProcessingStatus;
  reason?: string;
}

/**
 * Operations refer to steps the processing view has to take to reach a final
 * processing status. An operation's status will be:
 *  - ASLEEP; when nothing has happened yet, eg. initial declaration of the state
 *  - INITIALIZED; when the an attempt for a result is started, eg. a fetch request
 *  - PENDING; if some partial result has been received but process is not yet finished
 *  - REDIRECT; if the user needs to be redirected to complete the operation
 *  - FAILED; if the operation finished but was not successful
 *  - SUCCEEDED; if the operation finished and was successful
 */

enum OperationStatus {
  ASLEEP = 'ASLEEP',
  INITIALIZED = 'INITIALIZED',
  PENDING = 'PENDING',
  UPDATING = 'UPDATING',
  REDIRECT = 'REDIRECT',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
}

interface OperationState {
  status: OperationStatus;
  reason?: string;
  retries?: number;
  redirectData?: Redirect;
  type?: string
}

const ProcessingPaymentView = ({
  location: {
    formData,
  },
}: {
  location: {
    formData?: IOrderData;
  }
}) => {
  const { state, dispatch } = useContext(Store);
  const [processingState, setProcessingState] = useState(() => {
    const status = ProcessingStatus.PENDING;
    return {
      status,
    } as ProcessingState;
  });

  const [saveBillingInfoState, setSaveBillingInfoState] = useState(() => {
    const status = !formData ? OperationStatus.SUCCEEDED : OperationStatus.ASLEEP;
    return {
      status,
    } as OperationState;
  });

  const [saveCardState, setSaveCardState] = useState(() => {
    const status = !formData ? OperationStatus.PENDING : OperationStatus.ASLEEP;
    const retries = 0;
    return {
      status,
      retries,
    } as OperationState;
  });

  const [saveCardReferenceState, setSaveCardReferenceState] = useState({
    status: OperationStatus.ASLEEP,
  } as OperationState);

  const [getCardReferenceState, setGetCardReferenceState] = useState({
    status: OperationStatus.ASLEEP,
  } as OperationState);

  const [checkOrdersState, setCheckOrdersState] = useState({
    status: OperationStatus.ASLEEP,
  } as OperationState);

  const [pollStatusState, setPollStatusState] = useState(() => {
    const status = OperationStatus.ASLEEP;
    const retries = 0;
    return {
      status,
      retries,
    } as OperationState;
  });

  const [jobUuid, setJobUuid] = useState('');

  const [cardReference, setCardReference] = useState({
    cardNetwork: 'Unknown',
    PANTruncation: '',
    expiryMonth: '',
    expiryYear: '',
  });

  useEffect(() => {
    if (processingState.status !== ProcessingStatus.PENDING) return;
    if (saveBillingInfoState.status !== OperationStatus.ASLEEP) return;

    setSaveBillingInfoState({ status: OperationStatus.PENDING });

    Api.setBillingInformation(formData!.userInfo, state.user?.accessToken).then(() => {
      state.setUserDataValue('billingAddress', formData?.userInfo, dispatch);
      setSaveBillingInfoState({ status: OperationStatus.SUCCEEDED });
    }).catch((error) => {
      /* eslint-disable-next-line no-console */
      console.error(error);
      setSaveBillingInfoState({ status: OperationStatus.FAILED });
      setProcessingState({
        status: ProcessingStatus.FAILED,
        reason: 'Failed to save billing information',
      });
    });
  }, []);

  useEffect(() => {
    if (processingState.status !== ProcessingStatus.PENDING) return;
    if (saveBillingInfoState.status !== OperationStatus.SUCCEEDED) return;
    if (saveCardState.status !== OperationStatus.ASLEEP) return;

    setSaveCardState((previous) => ({ ...previous, status: OperationStatus.INITIALIZED }));

    const {
      paymentMethod: {
        encryptedCardNumber,
        encryptedExpiryMonth,
        encryptedExpiryYear,
        encryptedSecurityCode,
      },
    } = formData!.creditCardInfo;

    const creditCardBody = {
      encryptedCardNumber,
      encryptedExpiryMonth,
      encryptedExpiryYear,
      encryptedSecurityCode,
    };

    Api.initializeSaveCard(creditCardBody, state.user?.accessToken).then(() => {
      setSaveCardState((previous) => ({ ...previous, status: OperationStatus.PENDING }));
    }).catch((error) => {
      /* eslint-disable-next-line no-console */
      console.error(error);
      setSaveCardState((previous) => ({ ...previous, status: OperationStatus.FAILED }));
      setProcessingState({
        status: ProcessingStatus.FAILED,
        reason: 'Failed to initialize save card',
      });
    });
  }, [saveBillingInfoState]);

  useEffect(() => {
    if (processingState.status !== ProcessingStatus.PENDING) return;
    if (saveCardState.status !== OperationStatus.PENDING) return;

    setSaveCardState((previous) => ({ ...previous, status: OperationStatus.UPDATING }));
    Api.getTransactionStatus(state.user?.accessToken).then(({ status, type }) => {
      if (type !== 'save-card') {
        /* eslint-disable-next-line no-console */
        console.error('Transaction was not of type save-card');
        setSaveCardState({ status: OperationStatus.FAILED });
        setProcessingState({
          status: ProcessingStatus.FAILED,
          reason: 'Transaction was not of type save-card',
        });
      } else {
        switch (status) {
          case 'redirect':
            Api.getTransactionRedirect(state.user?.accessToken, 'update').then((redirectData) => {
              setSaveCardState({
                status: OperationStatus.REDIRECT,
                redirectData,
              });
            }).catch((error) => {
              /* eslint-disable-next-line no-console */
              console.error(error);
              setSaveCardState({ status: OperationStatus.FAILED });
              setProcessingState({
                status: ProcessingStatus.FAILED,
                reason: 'Failed to get redirect data',
              });
            });
            break;
          case 'pending':
            if (saveCardState.retries! >= 5) {
              setSaveCardState({ status: OperationStatus.FAILED });
              setProcessingState({
                status: ProcessingStatus.FAILED,
                reason: 'Save card status was not succeeded after 5 pollings',
              });
            } else {
              setTimeout(() => {
                setSaveCardState((prev) => ({
                  status: OperationStatus.PENDING,
                  retries: prev.retries! + 1,
                }));
              }, REACT_APP_POLLING_RETRY_DELAY_MS);
            }
            break;
          case 'succeeded':
            setSaveCardState({ status: OperationStatus.SUCCEEDED });
            break;
          default:
            setProcessingState({
              status: ProcessingStatus.FAILED,
              reason: 'Operation save-card has failed',
            });
        }
      }
    }).catch((error) => {
      /* eslint-disable-next-line no-console */
      console.error(error);
      setSaveCardState((previous) => ({ ...previous, status: OperationStatus.FAILED }));
      setProcessingState({
        status: ProcessingStatus.FAILED,
        reason: 'Failed to initialize save card',
      });
    });
  }, [saveBillingInfoState, saveCardState]);

  useEffect(() => {
    if (processingState.status !== ProcessingStatus.PENDING) return;
    if (saveCardState.status !== OperationStatus.SUCCEEDED) return;
    if (saveCardReferenceState.status !== OperationStatus.ASLEEP) return;

    setSaveCardReferenceState({ status: OperationStatus.PENDING });
    Api.updateCardReference(state.user?.accessToken).then(() => {
      setSaveCardReferenceState({ status: OperationStatus.SUCCEEDED });
    }).catch((error) => {
      /* eslint-disable-next-line no-console */
      console.error(error);
      setSaveCardReferenceState({ status: OperationStatus.FAILED });
      setProcessingState({
        status: ProcessingStatus.FAILED,
        reason: 'Failed to save card reference',
      });
    });
  }, [saveCardState]);

  useEffect(() => {
    if (processingState.status !== ProcessingStatus.PENDING) return;
    if (saveCardReferenceState.status !== OperationStatus.SUCCEEDED) return;
    if (getCardReferenceState.status !== OperationStatus.ASLEEP) return;

    setGetCardReferenceState({ status: OperationStatus.PENDING });
    Api.getCardReferences(state.user?.accessToken).then(({ cardReferences }) => {
      if (cardReferences.length === 0) {
        setGetCardReferenceState({ status: OperationStatus.FAILED });
        setProcessingState({
          status: ProcessingStatus.FAILED,
          reason: 'No saved cards found',
        });
      } else {
        setCardReference(cardReferences[0]);
        const cardRef = cardReferences[0];
        const cardInfo = `${transform.renameCardNetwork(cardRef.cardNetwork)} ${
          cardRef.PANTruncation
        } - ${cardRef.expiryMonth}/${cardRef.expiryYear.slice(2)}`;

        state.setUserDataValue('cardReferences', cardReferences, dispatch);
        state.setUserDataValue('cardRefLabel', cardInfo, dispatch);
        setGetCardReferenceState({ status: OperationStatus.SUCCEEDED });
        setProcessingState({ status: ProcessingStatus.PENDING });
      }
    }).catch((error) => {
      /* eslint-disable-next-line no-console */
      console.error(error);
      setGetCardReferenceState({ status: OperationStatus.FAILED });
      setProcessingState({
        status: ProcessingStatus.FAILED,
        reason: 'Failed to fetch saved cards',
      });
    });
  }, [saveCardReferenceState]);

  useEffect(() => {
    if (processingState.status !== ProcessingStatus.PENDING) return;
    if (getCardReferenceState.status !== OperationStatus.SUCCEEDED) return;
    if (checkOrdersState.status !== OperationStatus.ASLEEP) return;

    setCheckOrdersState({ status: OperationStatus.PENDING });

    Api.checkForUnpaidOrders(state.user?.accessToken).then((uuid) => {
      setCheckOrdersState({ status: OperationStatus.SUCCEEDED });
      setJobUuid(uuid);
    }).catch((err) => {
      /* eslint-disable-next-line no-console */
      console.error(err);
      setCheckOrdersState({ status: OperationStatus.FAILED });
      setProcessingState({
        status: ProcessingStatus.FAILED,
        reason: 'Failed to reactivate subscriptions. Please add your payment information again.',
      });
    });
  }, [getCardReferenceState]);

  useEffect(() => {
    if (processingState.status !== ProcessingStatus.PENDING) return;
    if (checkOrdersState.status !== OperationStatus.SUCCEEDED) return;
    if (pollStatusState.status === OperationStatus.SUCCEEDED
      || pollStatusState.status === OperationStatus.FAILED) return;
    if (!jobUuid) return;

    Api.getOrderStatus(jobUuid, state.user?.accessToken).then(({ status, error }) => {
      if (status === 'success') {
        setPollStatusState((prev) => ({ ...prev, status: OperationStatus.SUCCEEDED }));
        setProcessingState({ status: ProcessingStatus.SUCCEEDED });
        state.setUserDataValue('infoUpdated', true, dispatch);
      } else if (status === 'pending') {
        setPollStatusState((prev) => ({ ...prev, status: OperationStatus.PENDING }));
        if (pollStatusState.retries! >= 5) {
          setPollStatusState((prev) => ({ ...prev, status: OperationStatus.FAILED }));
          setProcessingState({ status: ProcessingStatus.FAILED, reason: 'Failed after five pollings' });
        } else {
          setTimeout(() => {
            setPollStatusState((prev) => ({
              ...prev,
              retries: prev.retries! + 1,
            }));
          }, REACT_APP_POLLING_RETRY_DELAY_MS);
        }
      } else {
        setPollStatusState((prev) => ({ ...prev, status: OperationStatus.FAILED }));
        setProcessingState({ status: ProcessingStatus.FAILED, reason: error });
      }
    }).catch((err) => {
      /* eslint-disable-next-line no-console */
      console.error(err);
      setPollStatusState((prev) => ({ ...prev, status: OperationStatus.FAILED }));
      setProcessingState({
        status: ProcessingStatus.FAILED,
        reason: 'Failed to reactivate subscriptions.\nPlease confirm you have the sufficient funds and add your payment information again.',
      });
    });
  }, [checkOrdersState, jobUuid, pollStatusState.retries]);

  const successful = processingState.status === ProcessingStatus.SUCCEEDED;

  const heading = successful
    ? intl.get('MANAGE_PAYMENT__SUCCESS_HEADING')
    : intl.get('MANAGE_PAYMENT__ERROR_DESCRIPTION');

  const description = successful
    ? intl.get('MANAGE_PAYMENT__SUCCESS_DESCRIPTION', {
      cardNetwork: transform.renameCardNetwork(cardReference.cardNetwork),
      PANTruncation: cardReference.PANTruncation,
    })
    : processingState.reason!;

  const formRef = useCallback((form) => {
    if (!form) return;
    (form as HTMLFormElement).submit();
  }, []);

  const pathname = successful ? '/account' : '/account/payment/edit';

  const buttonText = successful ? intl.get('MANAGE_PAYMENT__SUCCESS_BUTTON') : intl.get('MANAGE_PAYMENT__ERROR_BUTTON');

  return (
    <div data-testid="processing-view" className="web-view-container">
      <div id="processing-view-content">
        {
          (![ProcessingStatus.SUCCEEDED, ProcessingStatus.FAILED].includes(processingState.status))
          && <Processing altText="saving billing information" />
        }
        {
          [ProcessingStatus.SUCCEEDED, ProcessingStatus.FAILED].includes(processingState.status)
          && (
            <Confirmation
              successful={successful}
              heading={heading}
              description={description}
              redirectIsDeepLink={false}
              to={{ pathname }}
              buttonText={buttonText}
            />
          )
        }
        {
          saveCardState.status === OperationStatus.REDIRECT
          && (
            <form ref={formRef} data-testid="redirect-form" action={saveCardState.redirectData!.url} method={saveCardState.redirectData!.method}>
              <input readOnly name="MD" value={saveCardState.redirectData!.data!.MD || ''} type="hidden" />
              <input readOnly name="PaReq" value={saveCardState.redirectData!.data!.PaReq || ''} type="hidden" />
              <input readOnly name="TermUrl" value={saveCardState.redirectData!.data!.TermUrl || ''} type="hidden" />
            </form>
          )
        }
      </div>
    </div>
  );
};

export default ProcessingPaymentView;
