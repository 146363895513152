import React from 'react';
import intl from 'react-intl-universal';
import './subscriptionDetails.scss';
import TCSenseCarNoSignal from './icons/TCSenseCarNoSignal.svg';
import TCSenseCar from './icons/TCSenseCar.svg';
import TCInfo from './icons/TCInfo.svg';
import TCWireless from './icons/TCWireless.svg';
import TCWirelessNoSignal from './icons/TCWirelessNoSignal.svg';
import { ISubscription } from '~interfaces/subscriptions';

interface ISubscriptionDetails {
  subscription: ISubscription;
  largeIcons?: boolean;
}

const SubscriptionDetails = ({
  subscription, largeIcons,
}: ISubscriptionDetails) => {
  const classItemContainer = largeIcons
    ? 'subscription-item-container subscription-item-container--large'
    : 'subscription-item-container';
  const classIcon = largeIcons ? 'subscription-icon-wrapper--large' : 'subscription-icon-wrapper';
  const carIcon = subscription.status === 'active' ? TCSenseCar : TCSenseCarNoSignal;
  const wifiIcon = subscription.wifiEnabled ? TCWireless : TCWirelessNoSignal;

  return (
    <div className="subscription-container">
      {subscription.price !== 0 && subscription.freeMonths > -1
                 && subscription.usedMonths >= subscription.freeMonths && <p className="subscription-item-price-text">{`${subscription.price} ${subscription.currency}`}</p>}
      {subscription.licensePlate && (
        <div className={classItemContainer}>
          <img src={carIcon} className={classIcon} alt="Car" />
          <p className="subscription-item-text">{`${subscription.licensePlate.toUpperCase()}`}</p>
        </div>
      )}
      {subscription.imei && (
        <div className={classItemContainer}>
          <img src={TCInfo} className={classIcon} alt="IMEI" />
          <p className="subscription-item-text">{`${subscription.imei}`}</p>
        </div>
      )}
      {subscription.status && (
        <div className={classItemContainer}>
          <img src={wifiIcon} className={classIcon} alt="WIFI" />
          {subscription.isPaydrive && (
          <p className="subscription-item-text">
            {subscription.isPremium && subscription.wifiEnabled ? intl.get('ENABLED') : intl.get('DISABLED')}
          </p>
          )}
          {!subscription.isPaydrive && (
          <p className="subscription-item-text">
            {subscription.wifiEnabled ? intl.get('ENABLED') : intl.get('DISABLED')}
          </p>
          )}
        </div>
      )}
    </div>
  );
};

export default SubscriptionDetails;
