import React, { useContext } from 'react';
import intl from 'react-intl-universal';
import ScrollToTop from '~components/scrollToTop/scrollToTop';
import BackButtonHeader from '~components/backButtonHeader/backButtonHeader';
import GridItemWithIcon from '~components/gridItemWithIcon/gridItemWithIcon';
import { Store } from '../store';
import '~shared/styles/webView.scss';
import '~shared/styles/formHeader.scss';
import { ISubscription } from '~interfaces/subscriptions';

const SubscriptionView = () => {
  const { state } = useContext(Store);

  const subscriptions: ISubscription[] = state.user?.subscriptions ?? [];

  const filteredSubscriptions = subscriptions.filter((subscription: ISubscription) => {
    if (subscription.isBasic) {
      if (subscriptions.find((s) => s.isPremium
          && s.sparkProvisioningIdentifier === subscription.sparkProvisioningIdentifier
          && s.isPaydrive
          && (s.status === 'active' || s.status === 'paused'))
          !== undefined) {
        return false;
      }
    }
    return true;
  });
  const activeSubs = filteredSubscriptions.filter(
    (subscription: ISubscription) => subscription.status === 'active' || subscription.status === 'paused' || (!subscription.isPaydrive && subscription.status === 'canceled'),
  );
  const inactiveSubs = filteredSubscriptions.filter(
    (subscription: ISubscription) => subscription.status === 'complete' || (subscription.isPaydrive && subscription.status === 'canceled'),
  );

  return (
    <div className="web-view-container">
      <ScrollToTop />
      <div className="row">
        <BackButtonHeader title={intl.get('SUBSCRIPTION__MANAGE_SUBSCRIPTION')} to="/account" />
      </div>
      <section className="row section-wrapper">
        {activeSubs.length > 0 && (
          <GridItemWithIcon
            pageTitle={intl.get('SUBSCRIPTION__ACTIVE_SUBSCRIPTIONS')}
            subscriptions={activeSubs}
          />
        )}
        {inactiveSubs.length > 0 && (
          <GridItemWithIcon
            pageTitle={intl.get('SUBSCRIPTION__INACTIVE_SUBSCRIPTIONS')}
            subscriptions={inactiveSubs}
          />
        )}
      </section>
    </div>
  );
};

export default SubscriptionView;
