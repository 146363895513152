import React from 'react';
import intl from 'react-intl-universal';
import Text from '../text/text';
import { Checkmark, AlertFilled, Canceled } from '~shared/svg-icons/index';
import './gridItemWithIcon.scss';
import { ISubscription } from '~interfaces/subscriptions';
import SubscriptionDetails from '~components/subscriptionDetails/subscriptionDetails';

interface IProps {
  pageTitle: string;
  subscriptions: ISubscription[];
}

const GridItemWithIcon = ({ pageTitle, subscriptions }: IProps) => (
  <div className="grid-item-wrapper">
    {pageTitle && <h2 className="title">{pageTitle}</h2>}
    {subscriptions.map((subscription) => (
      <div key={subscription.id}>
        <div className="grid-item">
          <div className="icon-wrapper">
            {subscription.status === 'active' && <Checkmark />}
            {subscription.status === 'canceled' && <Canceled />}
            {subscription.status === 'complete' && <Canceled />}
            {subscription.status === 'paused' && <AlertFilled />}
          </div>
          <div className="content-wrapper">
            <Text bold>
              {`${subscription.title} ${subscription.description}`}
            </Text>
          </div>
        </div>
        <SubscriptionDetails
          subscription={subscription}
          largeIcons
        />
        <div className="grid-item">
          <div className="content-wrapper">
            {(subscription.price !== 0 && subscription.freeMonths > -1
                 && subscription.usedMonths >= subscription.freeMonths && (subscription.status === 'active' || subscription.status === 'paused'))
                 && <Text bold>{intl.get('PAYMENT')}</Text>}
            {subscription.status === 'active' || subscription.status === 'paused' ? (
              <div>
                {(subscription.price !== 0 && subscription.freeMonths > -1
                 && subscription.usedMonths >= subscription.freeMonths) && (
                 <Text>
                   {intl.get('SUBSCRIPTION__NEXT_RUN', {
                     nextRun: subscription.nextRun.slice(0, 10),
                     price: subscription.price,
                     currency: subscription.currency,
                   })}
                 </Text>
                )}
              </div>
            ) : (
              <div>
                {subscription.isPaydrive
              && <Text>{intl.get('SUBSCRIPTION__CANCELED')}</Text>}
                {!subscription.isPaydrive && subscription.status === 'complete'
              && <Text>{intl.get('SUBSCRIPTION__CANCELED')}</Text>}
              </div>
            )}
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default GridItemWithIcon;
