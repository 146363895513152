import React, { useContext, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import FormContext from '~contexts/formContext';
import AdyenStyledFormItem from '~components/AdyenStyledForm/AdyenStyledFormItem/AdyenStyledFormItem';
import { IBillingAddress } from '~interfaces/cardInfo';
import './AdyenStyledForm.scss';

interface IProps {
  title?: string;
  onChange: React.Dispatch<
  React.SetStateAction<{
    data: IBillingAddress;
    isValid: boolean;
  }>
  >;
}

const AdyenStyledForm = ({ title, onChange }: IProps) => {
  const [formValid, setFormValid] = useState(false);
  const formContext = useContext(FormContext);
  const {
    form: {
      firstName, lastName, street, postalCode, telephone, city, country, email,
    },
  } = formContext;

  const checkFormValid = () => {
    const form = Object.values(formContext.form);
    for (let field = 0; field < form.length; field += 1) {
      if (form[field].invalidReason || !form[field].value) return false;
    }
    return true;
  };

  useEffect(() => {
    setFormValid(checkFormValid);
  });

  useEffect(() => {
    onChange({
      data: {
        firstName: firstName.value,
        lastName: lastName.value,
        street: street.value,
        postalCode: postalCode.value,
        email: email.value,
        city: city.value,
        telephone: telephone.value,
        country: country.value,
      },
      isValid: formValid,
    });
  }, [
    firstName.value,
    lastName.value,
    street.value,
    postalCode.value,
    email.value,
    city.value,
    telephone.value,
    country.value,
    formValid,
  ]);

  return (
    <div className="adyenForm-container">
      {title && <h3>{title}</h3>}
      <form>
        <div className="adyenForm-row">
          <AdyenStyledFormItem
            name={firstName.name}
            placeholder={intl.get('FIRST_NAME')}
            value={firstName.value}
            invalidReason={firstName.invalidReason}
          />
          <AdyenStyledFormItem
            name={lastName.name}
            placeholder={intl.get('LAST_NAME')}
            value={lastName.value}
            invalidReason={lastName.invalidReason}
          />
        </div>
        <div className="adyenForm-row">
          <AdyenStyledFormItem
            name={street.name}
            placeholder={intl.get('ADDRESS')}
            value={street.value}
            invalidReason={street.invalidReason}
          />
        </div>
        <div className="adyenForm-row">
          <AdyenStyledFormItem
            name={postalCode.name}
            placeholder={intl.get('POSTAL_CODE')}
            value={postalCode.value}
            invalidReason={postalCode.invalidReason}
          />
          <AdyenStyledFormItem
            name={city.name}
            placeholder={intl.get('CITY')}
            value={city.value}
            invalidReason={city.invalidReason}
          />
        </div>
        <div className="adyenForm-row">
          <AdyenStyledFormItem
            name={telephone.name}
            placeholder={intl.get('PHONE_NUMBER')}
            value={telephone.value}
            invalidReason={telephone.invalidReason}
          />
        </div>
        <div className="adyenForm-row">
          <select
            className="adyenForm-select"
            defaultValue={country.value}
            onChange={(e) => {
              formContext.setFieldValue('country', e.target.value);
            }}
          >
            <option value="" disabled>
              {intl.get('COUNTRY')}
            </option>
            <option value="SE">Sverige</option>
            <option value="DK">Danmark</option>
          </select>
        </div>
      </form>
    </div>
  );
};

export default AdyenStyledForm;
