import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AccountView from './containers/accountView';
import ProfileView from './containers/profileView';
import ProfileEditView from './containers/profileEditView';
import AddressEditView from './containers/addressEditView';
import SubscriptionView from './containers/subscriptionView';
import PaymentProfileView from './containers/paymentProfileView';
import PaymentEditView from './containers/paymentEditView';
import ReceiptView from './containers/receiptView';
import RegistrationView from './containers/registrationView';
import ProcessingRegistrationView from './containers/processingRegistrationView';
import ProcessingPaymentView from './containers/processingPaymentView';
import ProcessingProfileEditView from './containers/processingProfileEditView';

const Routes = () => (
  <Switch>
    <Route path="/" exact component={() => <Redirect to="/account" />} />
    <Route path="/registration" exact component={RegistrationView} />
    <Route path="/registration/processing" exact component={ProcessingRegistrationView} />
    <Route path="/account" exact component={AccountView} />
    <Route path="/account/profile" exact component={ProfileView} />
    <Route path="/account/profile/address/edit" exact component={AddressEditView} />
    <Route path="/account/profile/edit" exact component={ProfileEditView} />
    <Route path="/account/profile/processing" exact component={ProcessingProfileEditView} />
    <Route path="/account/subscription" exact component={SubscriptionView} />
    <Route path="/account/payment" exact component={PaymentProfileView} />
    <Route path="/account/payment/edit" exact component={PaymentEditView} />
    <Route path="/account/payment/processing" exact component={ProcessingPaymentView} />
    <Route path="/account/payment/receipt/:id" exact component={ReceiptView} />
  </Switch>
);

export default Routes;
