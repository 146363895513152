import React, {
  useState, useEffect, Suspense, useContext,
} from 'react';
import intl from 'react-intl-universal';
import jwt from 'jsonwebtoken';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Routes from './routes';
import { Api } from '~repositories/api';
import { Store } from './store';
import Processing from '~components/processing/processing';
import ErrorBox from '~components/ErrorBox/ErrorBox';
import '~shared/styles/app.scss';
import useQuery from './hooks/useQuery';
import svSe from './locales/sv-SE.json';
import daDk from './locales/da-DK.json';
import enUs from './locales/en-US.json';

const locales = {
  'sv-SE': svSe,
  'da-DK': daDk,
  'en-US': enUs,
};

interface IAzureTokenPayload {
  email: string;
  family_name: string;
  given_name: string;
}

const App: React.FC<RouteComponentProps> = () => {
  const [localeLoaded, setLocaleLoaded] = useState(false);
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [tokenLoaded, setTokenLoaded] = useState(false);
  const [oneTimePass] = useState(useQuery().get('otp'));
  const { state, dispatch } = useContext(Store);

  useEffect(() => {
    intl
      .init({
        currentLocale: (() => {
          const browserLanguage = (navigator.languages && navigator.languages[0])
          || navigator.language;

          if (/^sv\b/.test(browserLanguage)) {
            return 'sv-SE';
          }
          if (/^da\b/.test(browserLanguage)) {
            return 'da-DK';
          }
          return 'en-US';
        })(),
        locales,
      })
      .then(() => {
        setLocaleLoaded(true);
      });
  }, []);

  useEffect(() => {
    if (oneTimePass) {
      Api.getAccessToken(oneTimePass)
        .then((response) => {
          const { accessToken } = response;
          const decodedToken = jwt.decode(accessToken) as IAzureTokenPayload | null;

          if (decodedToken?.email) {
            // Remove snake-case when support in BE.
            const { email, family_name: familyName, given_name: givenName } = decodedToken;
            state.setUserDataValue('accessToken', accessToken, dispatch);
            state.setUserDataValue('email', email, dispatch);

            if (familyName || givenName !== undefined) {
              state.setUserDataValue('givenName', givenName, dispatch);
              state.setUserDataValue('familyName', familyName, dispatch);
            }
            setTokenIsValid(true);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        })
        .finally(() => {
          setTokenLoaded(true);
        });
    } else {
      setTokenLoaded(true);
    }
  }, [oneTimePass]);

  if (tokenLoaded && !tokenIsValid) {
    return <ErrorBox possibleSolution={intl.get('RESTART_APPLICATION')} />;
  }
  if (!tokenLoaded || !localeLoaded) {
    return <Processing altText="loading application" />;
  }

  return (
    <div className="App">
      <Suspense fallback={<Processing altText="loading application" />}>
        <Routes />
      </Suspense>
    </div>
  );
};

const AppRouter = withRouter(App);

export default AppRouter;
