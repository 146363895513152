import React from 'react';
import { Link } from 'react-router-dom';
import { Arrow } from '~shared/svg-icons';
import './linkItem.scss';

interface IProps {
  title: string;
  subTitle?: string;
  description?: string;
  to: {
    pathname: string;
    state?: object;
  };
}

const LinkItem = (props: IProps) => {
  const {
    title,
    subTitle,
    description,
    to,
  } = props;

  return (
    <Link
      className={`link-item-wrapper${subTitle ? ' profile' : ''}`}
      to={{ pathname: to.pathname, state: to.state }}
    >
      <div className="title-wrapper">
        {subTitle ? (
          <>
            <h2>{title}</h2>
            <p className="sub-title">{subTitle}</p>
          </>
        ) : (
          <p className="title">{title}</p>
        )}
      </div>
      {description && <p className="description">{description}</p>}
      <div className="icon-wrapper">
        <Arrow />
      </div>
    </Link>
  );
};

export default LinkItem;
