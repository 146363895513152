import React, { useState, useContext } from 'react';
import intl from 'react-intl-universal';
import AdyenComponent from '@dewire/adyen-react';
import { Link } from 'react-router-dom';
import AdyenStyledForm from '~components/AdyenStyledForm/AdyenStyledForm';
import BackButtonHeader from '~components/backButtonHeader/backButtonHeader';
import { IBillingAddress, IPaymentMethod } from '~interfaces/cardInfo';
import '~shared/styles/paymentEditView.scss';
import { ADYEN_ORIGIN_KEY, ADYEN_ENV } from '~config/config';
import { FormContextProvider, IUserFormContext } from '~contexts/formContext';
import { Store } from '../store';

// TODO - Handle errors for 'canceled', 'pending'
const PaymentEditView = () => {
  const [creditCardInfo, setCreditCardInfo] = useState<{
    data: IPaymentMethod;
    isValid: boolean;
  }>({ data: {} as IPaymentMethod, isValid: false });
  const [userInfo, setUserInfo] = useState<{
    data: IBillingAddress;
    isValid: boolean;
  }>({ data: {} as IBillingAddress, isValid: false });
  const { state } = useContext(Store);

  const initalUserForm: IUserFormContext = {
    form: {
      firstName: {
        placeholder: 'Förnamn',
        name: 'firstName',
        value: state.user?.billingAddress.firstName ?? '',
        invalidReason: '',
      },
      lastName: {
        placeholder: 'Efternamn',
        name: 'lastName',
        value: state.user?.billingAddress.lastName ?? '',
        invalidReason: '',
      },
      street: {
        placeholder: 'Address',
        name: 'street',
        value: state.user?.billingAddress.street ?? '',
        invalidReason: '',
      },
      postalCode: {
        placeholder: 'Postkod',
        name: 'postalCode',
        value: state.user?.billingAddress.postalCode ?? '',
        invalidReason: '',
      },
      city: {
        placeholder: 'Ort',
        name: 'city',
        value: state.user?.billingAddress.city ?? '',
        invalidReason: '',
      },
      email: {
        placeholder: 'Epost',
        name: 'email',
        value: state.user!.email,
        invalidReason: '',
      },
      telephone: {
        placeholder: 'Telefon',
        name: 'telephone',
        value: state.user?.billingAddress.telephone ?? '',
        invalidReason: '',
      },
      country: {
        placeholder: 'Land',
        name: 'country',
        value: state.user?.billingAddress.country ?? '',
        invalidReason: '',
      },
    },
    setFieldValue: () => {},
    setFieldInvalid: () => {},
  };

  return (
    <div className="web-view-container new-payment-view">
      <div className="row">
        <BackButtonHeader
          title={
            state.user?.cardReferences
              ? intl.get('NEW_PAYMENT__TITLE')
              : intl.get('NEW_PAYMENT__ENTER_PAYMENT_METHOD')
          }
          to="/account/payment/"
        />
      </div>

      <section className="row section-wrapper new-payment-view__payment-wrapper">
        <h2 className="section-title">{intl.get('NEW_PAYMENT__ENTER_NEW_PAYMENT_METHOD')}</h2>
        <div className="row">
          <h3>{intl.get('CARD_DETAILS__CUSTOMER_INFORMATION')}</h3>
          <FormContextProvider initialValue={initalUserForm}>
            <AdyenStyledForm onChange={setUserInfo} />
          </FormContextProvider>
        </div>
        <div className="row">
          <h3>{intl.get('CARD_DETAILS')}</h3>
          <AdyenComponent
            onChange={setCreditCardInfo}
            config={{
              locale: intl.getInitOptions().currentLocale,
              environment: ADYEN_ENV,
              originKey: ADYEN_ORIGIN_KEY,
            }}
          />
        </div>
      </section>

      <div className="row footer">
        {userInfo.isValid && creditCardInfo.isValid ? (
          <Link
            className="button no-border green"
            to={{
              pathname: '/account/payment/processing',
              formData: {
                userInfo: userInfo.data,
                creditCardInfo: creditCardInfo.data,
              },
            }}
          >
            {intl.get('NEW_PAYMENT__SUBMIT')}
          </Link>
        ) : (
          <button type="button" disabled className="button no-border gray">
            {intl.get('NEW_PAYMENT__SUBMIT')}
          </button>
        )}
      </div>
    </div>
  );
};

export default PaymentEditView;
